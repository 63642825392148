import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import { graphql } from 'gatsby'
import { DateTime } from 'luxon'

import Layout from '@c/layout'
import Breadcrumb from '@c/breadcrumb'
import PageHeader from '@c/page-header'
import AuthorCard from '@c/author-card'
import ShareBox from '@c/share-box'
import RedLine from '@c/red-line'
import Guide from '@m/Guide'
import makeSlug from '@u/make-slug'
import ResourceAuthorCard from '@c/resource-author-card'

import './detail.css'
import { scrollIntoView } from '../../../utils/scroll-into-view'
import useForceBlank from '../../../hooks/use-force-blank'
import useCodeHighlighter from '@/hooks/use-code-highlighter'
import ProjectImage from '@/components/project-image'

const Page = ({ data }) => {
  const { guide, pageSettings } = data
  const {
    resourcesTitle,
    resourcesSlug,
    resourcesGuideTitle,
    resourcesGuideSlug,
    categorySlug
  } = pageSettings
  const model = new Guide(guide)
  const parentPages = [
    {
      title: resourcesTitle,
      url: makeSlug.generic(guide.locale, resourcesSlug)
    },
    {
      title: resourcesGuideTitle,
      url: makeSlug.generic(guide.locale, resourcesSlug, resourcesGuideSlug)
    }
  ]
  const title = guide.title
  const url = `https://zeo.org${model.getPath(pageSettings)}`
  const facebookLink = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    url
  )}`
  const twitterLink = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
    title
  )}&url=${encodeURIComponent(url)}`
  const linkedinLink = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
    url
  )}`
  const whatsappLink = `whatsapp://send?text=${encodeURIComponent(url)}`
  const mailLink = `mailto:?subject=${encodeURIComponent(
    title
  )}&body=${encodeURIComponent(url)}`
  let observer = null
  const [activeIndexElem, setActiveIndexElem] = useState(null)
  const content = useForceBlank(guide.content)

  useEffect(() => {
    observer = new IntersectionObserver(entries => {
      console.log('intersection callback:', entries)
      let activeElem = null

      entries.forEach(entry => {
        if (entry.intersectionRatio <= 0) {
          return
        }

        console.log(entry.target, entry.intersectionRatio)
        activeElem = entry.target.id
      })

      if (activeElem) {
        setActiveIndexElem(activeElem)
      }
    })
  }, [])

  useEffect(() => {
    const elems = guide.index
      .map(section => {
        return document.getElementById(section.sectionId)
      })
      .filter(elem => !!elem)

    elems.forEach(elem => observer.observe(elem))

    return () => elems.forEach(elem => observer.unobserve(elem))
  }, [observer, guide.index])
  useCodeHighlighter()
  return (
    <Layout
      locale={guide.locale}
      seo={{ ...model.getSeoComponentProps(pageSettings) }}
    >
      {/* BREADCRUMB */}
      <div className="container mb-60">
        <div className="row align-items-center">
          <div className="col-12 col-lg-6">
            <Breadcrumb
              className="resource-detail-breadcrumb"
              parentPages={parentPages}
              currentPage={title}
            />
          </div>
          <div className="col-12 col-lg-6 d-flex flex-wrap align-items-center justify-content-end mt-32 mt-lg-0 ml-auto">
            {/* author */}
            <AuthorCard
              photo={guide.author?.profileImage}
              name={`${guide.author?.firstName} ${guide.author?.lastName}`}
              nickname={guide.author?.nickname}
              categoryBase={makeSlug.generic(
                guide.locale,
                resourcesSlug,
                resourcesGuideSlug,
                categorySlug
              )}
              categories={guide.category ? [guide.category] : []}
              locale={guide.locale}
            />
            <p className="fs-small ml-32">
              {DateTime.fromJSDate(
                new Date(guide.publishDate)
              ).toFormat('dd MMM yyyy', { locale: guide.locale })}
            </p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <article className="blog-post container px-0">
              {/* header */}
              <header className="blog-post-header d-flex flex-column align-items-start justify-content-center">
                <div className="col-12 mb-40">
                  {guide.image && <ProjectImage image={guide.image} />}
                </div>
              </header>
              {/* body */}
              <div className="blog-post-body my-40">
                <div className="d-flex flex-row align-items-start">
                  <div className="col-2 d-none d-lg-block pl-32 u-sticky">
                    <RedLine />
                    {/* contents */}
                    {guide.index.map((interPageLink, index) => {
                      return (
                        <div
                          className={cn(
                            'content-jumplink',
                            activeIndexElem === interPageLink.sectionId &&
                              'content-jumplink-active'
                          )}
                          key={index}
                        >
                          <div
                            className="pt-16"
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                              scrollIntoView(interPageLink.sectionId)
                            }
                          >
                            {interPageLink.title}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                  <div className="col-12 col-lg-8">
                    <div className="col-12 pl-32">
                      {/* title */}
                      <PageHeader title={guide.title} />
                      <div className="d-block d-lg-none">
                        {/* sharebox */}
                        <ShareBox
                          className="d-flex flex-row"
                          facebookLink={facebookLink}
                          twitterLink={twitterLink}
                          mailLink={mailLink}
                          whatsappLink={whatsappLink}
                          linkedinLink={linkedinLink}
                        />
                      </div>
                    </div>
                    <div
                      className="blog-post-content mt-80 pl-32"
                      dangerouslySetInnerHTML={{ __html: content }}
                    />
                  </div>
                  <div className="col-2 d-none d-lg-flex justify-content-end u-sticky">
                    {/* sharebox */}
                    <ShareBox
                      className="d-flex flex-column pr-32"
                      facebookLink={facebookLink}
                      twitterLink={twitterLink}
                      mailLink={mailLink}
                      whatsappLink={whatsappLink}
                      linkedinLink={linkedinLink}
                    />
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
      <ResourceAuthorCard
        locale={guide.locale}
        author={guide.author}
        nickname={guide.author?.nickname}
        photo={guide.author?.profileImage}
      />
    </Layout>
  )
}

export default Page

export const query = graphql`
  query GuideDetailQuery($locale: String, $guideId: String) {
    guide: datoCmsGuide(id: { eq: $guideId }) {
      _allSlugLocales {
        locale
        value
      }
      locale
      title
      slug
      content
      publishDate
      category {
        id
        slug
        title
      }
      image {
        alt
        asset {
          localImage {
            extension
            publicURL
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }

      index {
        title
        sectionId
      }
      author {
        firstName
        lastName
        nickname
        jobPosition
        profileImage {
          alt
          asset {
            localImage {
              extension
              publicURL
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 30)
              }
            }
          }
        }
      }
      metaTags {
        ...MetaTags
      }
    }

    # page settings
    pageSettings: datoCmsPageSetting(locale: { eq: $locale }) {
      _allResourcesSlugLocales {
        locale
        value
      }
      _allResourcesGuideSlugLocales {
        locale
        value
      }
      resourcesTitle
      resourcesSlug
      resourcesGuideTitle
      resourcesGuideSlug
      categorySlug
    }
  }
`
